import { IPatchApartmentWhiteList, IPostDataApartmentWhiteList } from "@/api/models/retail/apartments.model";
import { DataService } from "@/api/services";

const getApartmentsWhiteList = () => {
	return new Promise((resolve, reject) => {
		DataService.getApartmentsWhiteList()
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const postApartmentsWhiteList = ({ commit }: any, { data }: { data: IPostDataApartmentWhiteList }) => {
	return new Promise((resolve, reject) => {
		DataService.postApartmentsWhiteList(data)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const patchApartmentsWhiteList = ({ commit }: any, { id, data }: IPatchApartmentWhiteList) => {
	return new Promise((resolve, reject) => {
		DataService.patchApartmentsWhiteList({ id, data })
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

export default {
	getApartmentsWhiteList,
	postApartmentsWhiteList,
	patchApartmentsWhiteList
};
