import { Apartment } from "@/api/models/address/apartment.model";
import whiteListActions from "./whiteListActions";

const saveApartment = ({ commit }: any, apartment: Apartment) => {
	commit("setApartment", apartment);
};

export default {
	saveApartment,
	...whiteListActions
};
