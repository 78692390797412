import { DropdownDataResDto } from "@/models/storage.model";
import httpClient from "../config/httpClient";
import { IPatchApartmentWhiteList, IPostDataApartmentWhiteList } from "../models/retail/apartments.model";

export abstract class DataService {
	private static readonly DEPENDENCY_URL = "/sys03/data/dependency";
	private static readonly GROUPS_URL = DataService.DEPENDENCY_URL.concat("/groups");
	private static readonly NONGROUPS_URL = DataService.DEPENDENCY_URL.concat("/nongroups");
	private static readonly DROPDOWN_URL = "/sys03/data/dropdown";

	public static getDependencyGroups() {
		return httpClient.get(this.GROUPS_URL);
	}

	public static getDependencyNonGroups() {
		return httpClient.get(this.NONGROUPS_URL);
	}

	public static getVoipPrefixes() {
		return this.getConfiguration("PROVINCE_PREFIXES");
	}

	public static getConfiguration(key: string) {
		const url = `/sys03/data/configuration/${key}`;
		return httpClient.get(url);
	}

	public static deleteConfiguration(key: string, data: any) {
		const url = `/admin/data/configuration/${key}`;
		return httpClient.delete(url, { data });
	}

	public static patchConfiguration(key: string, data: any) {
		const url = `/admin/data/configuration/${key}`;
		return httpClient.patch(url, data);
	}

	public static putConfiguration(key: string, data: any) {
		const url = `/admin/data/configuration/${key}`;
		return httpClient.put(url, data);
	}

	public static findDropdownData(name: string): Promise<DropdownDataResDto[]> {
		const url = `${DataService.DROPDOWN_URL}/${name}`;
		return httpClient.get<DropdownDataResDto[]>(url).then((response) => {
			const data = response.data;

			if (data && Array.isArray(data)) {
				return data.sort((a, b) => {
					const aDisplayName = a.displayName.toLowerCase();
					const bDisplayName = b.displayName.toLowerCase();
					if (aDisplayName < bDisplayName) return -1;
					if (aDisplayName > bDisplayName) return 1;
					return 0;
				});
			}

			return data ?? [];
		});
	}

	public static getDropdowns() {
		const url = `/admin/data/dropdown`;
		return httpClient.get(url);
	}

	public static createDropdown(data: any) {
		const url = `/admin/data/dropdown`;
		return httpClient.post(url, data);
	}

	public static patchDropdown(id: number, data: any) {
		const url = `/admin/data/dropdown/${id}`;
		return httpClient.patch(url, data);
	}

	public static getDropdownData(id: number) {
		const url = `/admin/data/dropdown/${id}/data`;
		return httpClient.get(url);
	}

	public static createDropdownData(data: any) {
		const url = `/admin/data/dropdown/data`;
		return httpClient.post(url, data);
	}

	public static patchDropdownData(id: number, data: any) {
		const url = `/admin/data/dropdown/data/${id}`;
		return httpClient.patch(url, data);
	}

	public static getApartmentsWhiteList() {
		const url = `/sys03/apartments-whitelist?limit=50&used=false`;
		return httpClient.get(url);
	}

	public static postApartmentsWhiteList(data: IPostDataApartmentWhiteList) {
		const url = `/sys03/apartments-whitelist`;
		return httpClient.post(url, data);
	}

	public static patchApartmentsWhiteList({ id, data }: IPatchApartmentWhiteList) {
		const url = `/sys03/apartments-whitelist/${id}`;
		return httpClient.patch(url, data);
	}
}
