import { Route } from "vue-router";
import store from "@/store/index";
import { UIPAGE } from "@/constants/ui-page.constants";
import { findRoute } from "..";
import { RouteInfo } from "../types";

const getRootPath = () => {
	const permissions = ((store["state"] as any).user?.permissions as string[]) ?? [];
	const hasDashboardPermission = permissions.find((permission) => permission === UIPAGE.DASBOARD);
	if (hasDashboardPermission) {
		const route = findRoute({ id: UIPAGE.DASBOARD });
		return route?.path;
	}

	// get first permission page
	const firstPagePermission = permissions.find((permission) => permission.startsWith("/UI/PAGE/"));
	if (firstPagePermission) {
		const id = firstPagePermission.split(".*")[0];
		const route = findRoute({ id });
		return route?.path;
	}
};

const rootPathGuard = (to: Route, from: Route, next: (redirect?: string) => void) => {
	const path = getRootPath() || "/login";

	next(path);
};

const getRootChildrenPath = ({ children = [], defaultRouteId }: { children: RouteInfo[]; defaultRouteId: string }) => {
	const permissions = ((store["state"] as any).user?.permissions as string[]) ?? [];
	if (permissions.includes(defaultRouteId)) {
		const child = children.find((child) => child.id === defaultRouteId);

		if (child) return child.path;
	}

	const firstChildWithPermission = children.find((child) => {
		const permission = permissions.find((permission) => {
			const permissionRegexp = new RegExp(`^${permission}$`);

			return permissionRegexp.test(child.id);
		});
		return permission ? true : false;
	});
	if (firstChildWithPermission) return firstChildWithPermission.path;

	return;
};

const handleComponentRouterEvent = ({
	eventPath,
	basePath,
	children = [],
	defaultChildId,
	next
}: {
	eventPath: string;
	basePath: string;
	children: RouteInfo[];
	defaultChildId: string;
	next: (redirect?: string) => void;
}) => {
	if (eventPath == basePath) {
		const path = getRootChildrenPath({ children, defaultRouteId: defaultChildId });
		if (path) {
			return next(`${eventPath}/${path}`);
		}
		const rootPath = getRootPath();
		if (rootPath) return next(rootPath);
		next();
	} else {
		next();
	}
};

export { handleComponentRouterEvent, rootPathGuard, getRootPath, getRootChildrenPath };
